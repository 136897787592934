<template>
  <div class="relative">
    <!-- <div class="absolute mt-4 botom-0  w-full max-w-main  bg-primary-medium border-0 rounded-lg text-white px-2 py-2"> -->
    <div class="name">
      <div class="flex-grow">
        <div class="absolute text-lg font-bold text-white leading-5 mb-4">
      {{ currentSong.title }}
      </div>
      </div>
      <VueAudioPlayer
      ref="audioPlayer"
      :audio-list="[getSong()]"
      :show-play-loading="true"
      :showPlaybackRate='false'
      :themeColor="'#9537f8'"
      @pause="pause()"
      >
    >

      <!-- <template #play-start>
        <button class="custom-play-button">
          <i class="fa-solid fa-play" v-if="isPlaying" @click="togglePlay"></i>
          <i class="fa-solid fa-stop" v-else  @click="stopSong"></i>
        </button>
      </template> -->
    </VueAudioPlayer>
    <!-- </div> -->
  </div>
</div>
</template>

<script>
import VueAudioPlayer from '@liripeng/vue-audio-player';

export default {
  components: {
    VueAudioPlayer,
  },
  props: {
    currentSong: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  methods: {
    getSong() {
      const song = {
        src: this.currentSong.audio_url, // Required
      };

      return song;
    },
    togglePlay() {
      console.log('toggle');
      if (this.isPlaying) {
        this.$refs.audioPlayer.pause();
      } else {
        this.$refs.audioPlayer.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    pause() {
      this.isPlaying = false;
      this.$emit('pause-song');
    },
    stopSong() {
      console.log('test');
      this.$refs.audioPlayer.pause();
      this.isPlaying = false;
      this.$emit('stop-song');
    },
    async stop() {
      try {
      // Wait for the audio to be ready for playing
        await this.$refs.audioPlayer.pause(); // Assuming VueAudioPlayer places the native audio element as the first child
        this.isPlaying = false;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.error('Playback was aborted due to a loading issue: ', error);
        } else {
          console.error('An unexpected error occurred during playback: ', error);
        }
      }
    },
    async play() {
      try {
      // Wait for the audio to be ready for playing
        await this.$refs.audioPlayer.play(); // Assuming VueAudioPlayer places the native audio element as the first child
        this.isPlaying = true;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.error('Playback was aborted due to a loading issue: ', error);
        } else {
          console.error('An unexpected error occurred during playback: ', error);
        }
      }
    },
  },
};
</script>
<style scoped>
.botom-0{
  bottom:0px;
}
</style>
