<template>

  <div class="flex flex-col lg:flex-row gap-2 lg:gap-4 flex-grow">
    <div class="flex flex-col relative lg:flex-row gap-2 lg:gap-4 flex-grow">
        <div class="overflow-y-auto flex flex-col gap-3 lg:gap-6">
          <div class="mb-3 text-sm text-primary-muted">
            <div class="lg-3">
              <div class="flex items-center mb-2">
                <ToggleSwitch
                  class="mb-1"
                  v-model="instrumental"
                  @change="toggleInstrumental"
                  :disabled='creating'
                />
                <h1 class="mb-1 block px-1">Instrumental</h1>
              </div>
              <div class="flex items-center justify-between">
                <h1 class="mb-1 block text-white font-bold" v-if="!instrumental">
                  {{ $t('songBoard.lyrics') }}
                </h1>
                <div class="text-right"></div>
              </div>

              <div
                class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl"
                v-if="!instrumental"
              >
                <textarea
                  type="text"
                  rows="10"
                  maxlength="3000"
                  class="bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                  :placeholder="$t('songBoard.lyricsPlaceholder')"
                  v-model="lyrics"
                  :disabled='creating'
                ></textarea>
                <!-- <div class="input-errors" v-for="error of v$.password.old.$errors" :key="error.$uid">
                    <div class="error-msg">{{ $t(error.$message) }}</div>
                  </div> -->
                <!-- remaining number of characters like 10/2000 -->

                <div class="p-1 text-right text-primary-muted text-xs">
                  {{ lyrics?.length ?? 0 }}/3000
                </div>
              </div>
              <h1 class="mb-1 block text-white font-bold">
                {{ $t('songBoard.style') }}
              </h1>
              <div class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl">
                <textarea
                  rows="4"
                  type="password"
                  maxlength="120"
                  v-model="style"
                  class="no-resize bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                  :placeholder="$t('songBoard.stylePlaceholder')"
                  :disabled='creating'
                >
                </textarea>
                <div class="p-1 text-right text-primary-muted text-xs">
                  {{ style?.length ?? 0 }}/120
                </div>
                <!-- <div class="input-errors" v-for="error of v$.password.new.$errors" :key="error.$uid">
                    <div class="error-msg">{{ $t(error.$message) }}</div>
                  </div> -->
              </div>
              <h1 class="mb-1 block text-white font-bold">
                {{ $t('songBoard.title') }}
              </h1>
              <div class="mb-2.5 p-1 bg-primary-medium rounded rounded-xl">
                <textarea
                  type="text"
                  rows="1"
                  maxlength="80"
                  v-model="title"
                  class="no-resize bg-primary-medium border-active-0 border-0 w-full px-4 bg-[#E6E7E8] text-white rounded-lg text-sm focus:ring-0 focus:ring-primary-muted focus:border-primary-muted placeholder:text-white"
                  :placeholder="$t('songBoard.titlePlaceholder')"
                  :disabled='creating'
                >
                </textarea>
                <div class="text-right text-primary-muted text-xs">
                  {{ title?.length ?? 0 }}/80
                </div>
                <!-- <div class="input-errors" v-for="error of v$.password.confirm.$errors" :key="error.$uid">
                    <div class="error-msg">{{ $t(error.$message) }}</div>
                  </div> -->
              </div>
              <div class="flex flex-wrap items-center justify-between gap-3">
                <button
                  class="mt-2.5 h-10 px-7 bg-transparent inline-flex border border-primary-main items-center justify-center rounded-lg text-white font-semibold hover:brightness-110 active:brightness-95"
                  @click.prevent="clear"
                  :disabled='creating'
                >
                  {{ $t('songBoard.clear') }}
                </button>
                <button
                  class="mt-2.5 h-10 px-7 bg-primary-main inline-flex items-center justify-center rounded-lg text-white font-semibold hover:brightness-110 active:brightness-95"
                  :class="{ 'bg-transparent border border-primary-main ' : creating }"
                  @click="createSong"
                  :disabled='creating'
                >
                 <LoaderComp v-if='creating'/>
                 <div v-else> {{ $t('songBoard.createSong') }}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      <div class="flex-grow h-[650px] overflow-y-auto">
        <div class="flex flex-wrap items-center justify-between gap-1">
          <h1 class="py-1 my-px font-bold">Songs</h1>
        </div>
        <div class="mt-4 lg:mt-5 flex flex-col gap-1">
          <SongComp v-for="(song, index) in songs"
          :key="index"
          :song="song"
          :isSelected='song._id == selectedSong?._id'
          :isPlaying='song._id == currentSong?._id && isPlaying'
          @play-song="playSong"
          @stop-song='stopSong'
          @select-song='selectSong'
          />
          <div
            v-if="songs.length === 0"
            class="p-2 lg:p-4 border border-primary-light rounded-xl text-center"
          >
            No Songs yet
          </div>
        </div>
      </div>
    </div>

    <div class="w-full lg:max-w-72 h-[650px] overflow-y-auto mb-5 lg:mb-7 flex-shrink-0">
                <div class="mt-4 p-4 flex flex-col gap-4 rounded-xl">
                  <div class="flex gap-4 text-sm">
                    <div class="flex-grow">
                      <SongDetailComp
        v-if="selectedSong"
        :selected-song="selectedSong"
      />
                </div>
              </div>
            </div>
    </div>
    <!-- <div
          v-if="signals.length > 0"
          class="flex justify-center mt-4" >
            <button class="px-4 py-2 border border-primary-main rounded-lg text-primary-main hover:bg-primary-main hover:text-white" @click="loadMore">Load more</button>
          </div> -->
    <!-- </div> -->
  </div>
  <div
          v-if="currentSong"
  class="w-full bg-primary-medium border-0 rounded-lg text-white px-2 py-2">
      <PlayerComp

        ref="playerComp"
        :current-song="currentSong"
        @stop-song="stopSong"
        @pause-song='pauseSong'
      />
    </div>
</template>
<script>
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'vue-toastification';
import * as api from '../../api';
import SongComp from './songs/SongComp.vue';
import PlayerComp from './songs/PlayerComp.vue';
import SongDetailComp from './songs/SongDetailComp.vue';
import LoaderComp from '../LoaderComp.vue';

export default {
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      lyrics: null,
      style: null,
      title: null,
      songs: [],
      instrumental: false,
      creating: false,
      isPlaying: false,
      currentSong: null,
      selectedSong: null,
    };
  },
  components: {
    SongComp,
    ToggleSwitch,
    PlayerComp,
    SongDetailComp,
    LoaderComp,
  },
  mounted() {
    this.fetchSongs();
  },
  methods: {
    async fetchSongs() {
      try {
        this.songs = await api.fetchSongs();
      } catch (error) {
        console.error(error);
      }
    },
    changePassword() {
      console.log('change password');
    },
    clear() {
      this.lyrics = null;
      this.style = null;
      this.title = null;
    },
    toggleInstrumental() {
      this.lyrics = null;
    },
    pauseSong() {
      // called pause from player
      this.isPlaying = false;
    },
    playSong(song) {
      this.currentSong = song;
      this.isPlaying = true;

      // Wait for the DOM to update and additions time for any necessary loading within the PlayerComp
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.playerComp) {
            this.$refs.playerComp.play();
          }
        }, 100); // Delay by 100ms or an amount that suits your environment
      });
    },
    stopSong() {
      this.isPlaying = false;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.playerComp) {
            this.$refs.playerComp.stop();
          }
        }, 100); // Delay by 100ms or an amount that suits your environment
      });
    },
    selectSong(song) {
      console.log('selected');
      this.selectedSong = song;
    },
    async createSong() {
      try {
        this.creating = true;
        await api.createSong({
          lyrics: this.lyrics,
          style: this.style,
          title: this.title,
          instrumental: this.instrumental,
        });
        this.fetchSongs();
        this.clear();
      } catch (error) {
        console.error(error);
        this.toast.error(this.$t('errors.INTERNAL_ERROR.message'));
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>
<style>
.mb-10 {
  margin-bottom: 4rem;
}
.no-resize {
  resize: none;
  border: none;
  outline: none;
}
.h-\[650px\] {
  height: 650px;
}
/* active text area 0 border */
.border-active-0:focus {
  outline: 2px #aaa io !important;
}
.p-1 {
  padding: 0.1rem;
}
.p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider {
  background-color: rgb(149 55 248 / var(--tw-bg-opacity)) !important;
}
.mt-10 {
  margin-top: 6rem;
}
</style>
