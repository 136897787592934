<template>
<div class="w-full loader-wrapper">
  <PulseLoader :color="color"/>
</div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  data: () => ({
    color: '#9537f8',
  }),
  components: {
    PulseLoader,
  },
};
</script>
<style>
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
</style>
