<template>
  <div class="relative">
    <!-- <div class="absolute mt-4 botom-0  w-full max-w-main  bg-primary-medium border-0 rounded-lg text-white px-2 py-2"> -->
    <div class="name">

      <div class="flex-grow mb-4 ">
        <div v-if='selectedSong.status==="queued"' class="group relative  flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden mt-2">
        <LoaderComp/>
        </div>
        <div v-else class="group relative  flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden mt-2">
        <img class="w-full h-full" :src="selectedSong.image_url" />
        </div>
      </div>

      <div class="flex-grow">
        <div class="text-lg font-bold text-white leading-5 mb-4">
          {{ selectedSong.title }}
        </div>
        <div class="text-sm text-primary-muted">
          {{ selectedSong.tags }}
        </div>
      </div>
      <div
      class="w-full lg:w-full text-sm text-primary-muted flex-shrink-0 text-right lg:text-left"
    >
        <div>{{ getFormattedDate(selectedSong.created_at) }}</div>
        <div>{{ getFormattedTime(selectedSong.created_at) }}</div>
      </div>
    <!-- </div> -->

      <MarkDownRenderer :source="selectedSong.lyric" />
  </div>
</div>

</template>

<script>
import MarkDownRenderer from '../../MarkDownRenderer.vue';
import LoaderComp from '../../LoaderComp.vue';

export default {
  components: {
    MarkDownRenderer,
    LoaderComp,
  },
  props: {
    selectedSong: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFormattedDate(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    getFormattedTime(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
  },
};
</script>
<style scoped>
.botom-0{
  bottom:0px;
}
</style>
