<template>
  <div
    class="mt-2 container gap-4 rounded-xl flex flex-wrap lg:flex-nowrap lg:items-center hover:bg-primary-medium cursor-pointer"
      :class="{'bg-primary-medium': isSelected}"
    @click="selectSong"
  >
    <div v-if='song.status === "queued"'
    class="group relative w-20 h-20 flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden">
        <LoaderComp></LoaderComp>
    </div>
    <div v-else
      class="group relative w-20 h-20 flex-shrink-0 bg-primary-medium rounded-xl overflow-hidden"
    >
      <img class="w-full h-full" :src="song.image_url" />
      <Vue3Lottie
          v-if="isPlaying && !isHover"
          class="overlay-shown icon"
          :animationData="wavesJSON"
          :speed='0.7'
          :height="40"
          :width="40"
        />

      <div v-else class="absolute center forward overlay">

        <i class="fa-solid fa-play icon"
          @click="playSong"
          v-if="!isPlaying" ></i>
        <i class="fa-solid fa-pause icon"
          @click="stopSong"
          v-else
          ></i>
      </div>
    </div>
    <div class="flex flex-wrap w-full pr-10 items-center justify-between gap-1">
      <div class="flex-grow">
        <div class="text-lg font-bold text-white leading-5">
          {{ song.title }}
        </div>
        <div class="text-xs text-primary-muted">{{ song.tags }}</div>
      </div>
      <!-- Download Button -->
      <button
        class="text-white ml-auto   py-1 rounded-lg items-center w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer hover:bg-primary-main hover:text-white text-white"
        @click.stop="downloadWithAxios(song.audio_url, song.title+'.mp3')"
      >
      <i class="fa-solid fa-download"></i>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import wavesJSON from '../../../images/sound-animation.json';
import LoaderComp from '../../LoaderComp.vue';

export default {
  name: 'SongComp',
  props: {
    song: {
      type: Object,
      required: true,
    },
    isPlaying: {
      type: Boolean,
    },
    isSelected: {
      type: Boolean,
    },
  },
  components: {
    LoaderComp,
  },
  data() {
    return {
      wavesJSON,
    };
  },
  methods: {
    playSong() {
      this.$emit('play-song', this.song);
    },
    selectSong() {
      this.$emit('select-song', this.song);
    },
    stopSong() {
      this.$emit('stop-song', this.song);
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    getFormattedDate(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    getFormattedTime(date) {
      if (typeof date === 'number') {
        date = new Date(date * 1000);
      }
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
    downloadWithAxios(url, title) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log('error occured'));
    },
  },
};
</script>

<style scoped>
.show-play-on-hover {
  transition: all 0.3s;
}
.forward {
  z-index: 2000;
}
/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
}

/* Make the image to responsive */
.image {
  width: 100%;
  height: auto;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #000;
}

/* When you mouse over the container, fade in the overlay icon*/
.container:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #eee;
}
</style>
